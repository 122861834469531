import React from "react"

const Comparison = props => {
  return (
    <div className="bg-gray">
      <div className="background-gray-wave-separator-rotated"></div>
      <div className="section-content pb-20">
        <div className="w-full flex flex-col justify-center">
          <div className="container mx-auto text-center p-10">
            <div className="section-title color-home">Our comparison</div>
            <div className="section-text color-home">
              Our experience speaks for us. More than +800k artists already trust
              us.
            </div>
          </div>
          <div className="container mx-auto text-center">
            <div className="flex justify-center items-center flex-col md:flex-row">
              <div className="text-gray-600 border-2 rounded-xl border-gray-200 w-72 mt-5">
                <div className="color-home p-5 text-lg bg-gray-200 rounded-t-lg">
                  Features
                </div>
                <div className="text-base divide-y divide-light-gray-200">
                  <div className="p-2">Quick Purchase Process</div>
                  <div className="p-2">Customize Delivery</div>
                  <div className="p-2">Quality Guaranteed Results</div>
                  <div className="p-2">Fastest Delivery System</div>
                  <div className="p-2">Security & Privacy</div>
                  <div className="p-2">Customer Support 24/7</div>
                  <div className="p-2">100% Money-Back Guarantee</div>
                </div>
              </div>
              <div
                className="color-home border-2 rounded-xl w-72 mt-5"
                style={{ borderColor: "#000e44" }}
              >
                <div
                  className="text-white p-5 text-lg bg-gray-200 rounded-t-lg"
                  style={{ backgroundColor: "#000e44" }}
                >
                  Boostik
                </div>
                <div className="text-base divide-y divide-light-gray-200">
                  <div className="p-2" style={{ borderColor: "#000e44" }}>
                    <i className="bx bx-check-circle"></i>
                  </div>
                  <div className="p-2" style={{ borderColor: "#000e44" }}>
                    <i className="bx bx-check-circle"></i>
                  </div>
                  <div className="p-2" style={{ borderColor: "#000e44" }}>
                    <i className="bx bx-check-circle"></i>
                  </div>
                  <div className="p-2" style={{ borderColor: "#000e44" }}>
                    <i className="bx bx-check-circle"></i>
                  </div>
                  <div className="p-2" style={{ borderColor: "#000e44" }}>
                    <i className="bx bx-check-circle"></i>
                  </div>
                  <div className="p-2" style={{ borderColor: "#000e44" }}>
                    <i className="bx bx-check-circle"></i>
                  </div>
                  <div className="p-2" style={{ borderColor: "#000e44" }}>
                    <i className="bx bx-check-circle"></i>
                  </div>
                </div>
              </div>
              <div className="text-gray-400 border-2 rounded-xl border-gray-200 w-72 mt-5">
                <div className="color-home p-5 text-lg bg-gray-200 rounded-t-lg">
                  Competitors
                </div>
                <div className="text-base divide-y divide-light-gray-200">
                  <div className="p-2 color-home">
                    <i className="bx bx-check-circle"></i>
                  </div>
                  <div className="p-2 text-red-500">
                    <i className="bx bx-x-circle"></i>
                  </div>
                  <div className="p-2 text-red-500">
                    <i className="bx bx-x-circle"></i>
                  </div>
                  <div className="p-2 text-red-500">
                    <i className="bx bx-x-circle"></i>
                  </div>
                  <div className="p-2 text-red-500">
                    <i className="bx bx-x-circle"></i>
                  </div>
                  <div className="p-2 text-red-500">
                    <i className="bx bx-x-circle"></i>
                  </div>
                  <div className="p-2 text-red-500">
                    <i className="bx bx-x-circle"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Comparison
